.businesstype-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.horizontal-scroll::-webkit-scrollbar {
    display: none;
  }
  
.horizontal-scroll {
    width: 100%;
    height: 220px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    /* overflow: hidden; */
}

.category-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 0;
    transition: 0.5s all ease-out;
    height: 220px;
}

.category-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.category-circle {
    background: #fff;
    width: 82px;
    height: 82px;
    margin: 15px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.category-circle-selected {
    background: #85BE49; 
    width: 82px;
    height: 82px;
    margin: 15px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.category-circle:hover {
    background: rgba(133, 190, 73, 0.3);
    cursor: pointer;
    transform: scale(1.1);
}

.category-circle-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px solid #fff;
}

.category-circle-img1 {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px solid #fff;
}


.category-circle-img:hover {
    transform: scale(1.1);
}

.category-name {
    width: 82px;
    height: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 12px;
}

.category-name-selected {
    width: 82px;
    height: 15px;
    margin: 5px 10px;
    justify-content: center;
    align-items: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
}

@media only screen and (max-width: 1300px) {
    .horizontal-scroll {
        width: 100%;
         /* height: 200px; */
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: scroll;
    }
}
@media (max-width: 450px) {
    .category-circle-selected{
        width:65px;
        height: 65px;
    }
    .category-circle-img{
        width: 65px;
        height: 65px;
        /* border: 2px solid #fff; */
    }
    .category-circle-img1{
        width: 64px;
        height: 64px;
        /* border: 2px solid #fff; */
    }
    .category-circle{
        width: 65px;
        height: 65px;
    }
  }
