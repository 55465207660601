.notification-button {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    background-color: transparent;
    letter-spacing: 1px;
    font-family: 'Nunito', sans-serif;
    cursor: pointer;
}
.notification-box
{
    height: 100%;
    width: 100%;
    background-color: rgba(234, 238, 247, 0.3);
    ;
}

.Center{
    display: flex;
    margin: 0 auto;
    align-items: center;
}

@media (max-width: 700px) {

    .notification-button {
        display: flex;
        flex-direction: row;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-align: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: none;
        background-color: transparent;
    }
}
@media(max-width:500px){
    .delete{
        padding-top: 3px;
    }
}