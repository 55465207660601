.features_section {
  width: 100%;
  /* max-width:1200px; */
  margin: 0auto;
  /* padding:1rem 5rem; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  font-family: "Nunito", sans-serif;
  padding: 50px 30px;
  overflow-x: hidden;
}

.features_top {
  text-align: center;
}

.features_top h1 {
  margin-top: 1rem;
  letter-spacing: 0.25rem;
  color: rgba(29, 77, 79, 1);
}

.feature_item {
  display: flex;
  width: 45%;
  margin-top: 1rem;
  cursor: pointer;
  align-items: center;
}

.features_container {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

/* .featureIcon {
    width: 50px;
    height: 50px;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    align-items: center;

} */

.featureItem_info {
  padding: 1.5rem;
}

.featureItem_info h2 {
  color: #111111;
  letter-spacing: 0.1rem;
  margin-bottom: 0.3rem;
  font-size: 20px;
}

.featureItem_info p {
  font-size: 18px;
  color: #333333;
  padding-top: 2px;
}

.featureIcon_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 80%;
}

.featureIcon {
  width: 50px;
  height: 50px;
}

.become-a-seller-button-div {
  display: none;
}

.heading-text1 .responsive-text1 {
  display: inline;
}

@media (max-width: 1024px) {
  .heading-text1 .responsive-text1 {
    display: inline;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .heading-text1 .responsive-text1:first-of-type,
  .heading-text1 .responsive-text1:nth-of-type(2) {
    display: inline;
  }
  .heading-text1 .responsive-text1:nth-of-type(3) {
    display: block;
  }
}

@media (max-width: 768px) {
  .featureItem_info {
    padding: 10px;
  }
  .features_container {
    display: flex;
    flex-direction: column;
  }

  .feature_item {
    width: 100%;
    padding-top: 0;
    margin-top: 0;
  }

  .feature_item {
    display: flex;
    flex-direction: column;
  }

  .featureItem_info h3 {
    text-align: center;
    font-size: 17px;
  }

  .featureItem_info p {
    text-align: justify;
  }
  .become-a-seller-button-div {
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .features_section {
    padding: 40px 65px;
  }
}
@media (max-width: 426px) {
  .features_section {
    padding: 20px;
  }
}
@media (max-width: 376px) {
  .heading-text1 .responsive-text1 {
    display: inline;
  }
  .features_section {
    padding: 10px;
  }
  .featureItem_info p {
    font-size: 14px;
    padding-top: 10px;
    color: #333333;
  }
  .featureItem_info h2 {
    font-size: 16px;
  }
}
