.Card {
    background: #FFFFFF;
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    width: 150px;
    position: relative;
    overflow: hidden;
    padding: 17px;
    /* transition: all 0.2s; */
    align-items: center;
    z-index: 2;
}
.productname {
    width: 80%; 
    height:42px;
    font-size:14px;
    font-weight: 600;
    text-align: center;
    align-items:center;
    justify-content: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .Card {

        background: #FFFFFF;
        box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        width: 150px;
        position: relative;
        padding: 17px;
        /* transition: all 0.2s; */
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 2;
    }
}

@media only screen and (max-width: 767px) {
    .Card {
        background: #FFFFFF;
        box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
        border-radius: 20px;
        width: 150px;
        padding: 22px;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        z-index: 2;
    }
}

.gap {
    padding-top: 23px;
}

.Card-Image {
    width: 120px;
    height: 120px;
}
@media (max-width:450px){
    .Card-Image{
        width: 65px;
        height: 65px;
    }
}
.Product-Name {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .Product-Name {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 767px) {
    .Product-Name {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #000000;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.Price {
    padding-top: 7px;
    display: flex;
    flex-direction: row;
}

.Rupees {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    padding-right: 5px;
}

.Measure {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.3)
}

.addtocart{
    bottom: -14px;
    z-index: 100;
    position: absolute;
}