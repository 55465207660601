::placeholder {
  color: rgba(105, 168, 92, 0.8);
  font-weight: 400;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(105, 168, 92, 0.8);
  font-weight: 400;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(105, 168, 92, 0.8);
  font-weight: 400;
}
.padding-div {
  padding: 13px;
}

.description-location {
  max-width: 100%;
}

.button {
  background: #85be49;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  border-radius: 12px;
  padding: 10px;
  border: 1px #85be49;
  width: 100%;
  cursor: pointer;
  /* margin-left: 15px; */
}

.savebutton {
  font-family: "Nunito";
  background: #85be49;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  border-radius: 12px;
  padding: 10px;
  border: 1px #85be49;
  width: 100%;
  cursor: pointer;
  margin-left: 15px;
}

.FError {
  font-size: 0.875rem;
  color: #ea4335;
}

.divider {
  width: 200px;
  height: 0px;
  border: 1px solid #85be49;
}

.EditHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 25px;
  color: #000000;
}
.main-support {
  padding-bottom: 20px;
  /* padding: 10px 30px; */
  display: flex;
  justify-content: center;
}

.location {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subQuestion {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 19px;
  opacity: 0.8;
  color: #59626a;
}

.Addbutton {
  margin: auto;
  width: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 40px;
}
@media (max-width: 914px) {
  .main-support {
    padding: 10px;
  }
}

@media (max-width: 435px) {
  .padding-div {
    padding: 9px;
  }
}
@media (max-width: 376px) {
  .description-location {
    max-width: 250px;
  }
  .edit-location {
    display: none;
  }
}
