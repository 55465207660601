.Card {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 150px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    transition: all 0.2s;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Card:hover {
    transform: scale(1.1);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .Card {
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        width: 150px;
        position: relative;
        padding: 15px;
        transition: all 0.2s;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
    }

    .Card:hover {
        transform: scale(1.1);
        box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
    }
}

@media only screen and (max-width: 767px) {
    .Card {
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
        width: 150px;
        border-radius: 10px;
        padding: 30px;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
    }

    .Card:hover {
        transform: scale(1.1);
        box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
    }
}

.gap {
    padding-top: 23px;
    height: 3.125rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .gap {
        padding-top: 23px;
    }
}

@media only screen and (max-width: 767px) {
    .gap {
        padding-top: 13px;
    }
}

.Card-Image {
    width: 85px;
    height: 85px;
    border-radius: 10px;
}
@media (max-width:450px){
    .Card-Image{
        width: 65px;
        height: 65px;
    }
}
.store-image-div {
    align-items: center;
    display: flex;
    height: 130px;
    justify-content: center;
    position: relative;
    
}

.Store-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #000000;
    display: -webkit-box;

    -webkit-line-clamp: 2;

    -webkit-box-orient: vertical;

    overflow: hidden;

    text-overflow: ellipsis;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .Store-name {
        display: -webkit-box;

        -webkit-line-clamp: 2;

        -webkit-box-orient: vertical;

        overflow: hidden;

        text-overflow: ellipsis;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: #000000;
    }
}

@media only screen and (max-width: 767px) {
    .Store-name {
        display: -webkit-box;

        -webkit-line-clamp: 2;

        -webkit-box-orient: vertical;

        overflow: hidden;

        text-overflow: ellipsis;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #000000
    }
}
