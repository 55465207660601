.payment-details-container{
 width: 100%;
}
.wd-100{
        width: 100%;
}
.payment-rows {
    margin-right: -7px;
}
.flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.line1 {
    /* width: 634px; */
    width: 100%;
    height: 1px;
    margin-top: 26px;
    margin-bottom: 18px;
}
.line2 {
    /* width: 647px; */
    width: 100%;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 20px;
    position: relative;
    top: -14px;
}
.icon1 {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    fill: #5F5858;
}
.payment-label1 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.payment-label2 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 100%; */
    margin-top: 4px;
   
}
.payment-label3 {
 
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    
}
.payment-label4 {
    
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
   
}
.payment-label5 {

color: #000;
font-family: Nunito;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 100%;

}
.payment-label6 {
color: #2D93FB;
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 100%;
   
}
.payment-value1 {

color: #000;
font-family: Nunito;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 100%;
   
}
.payment-value2 {

color: #000;
font-family: Nunito;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 100%;
   
}
.payment-value3 {

color: #000;
font-family: Nunito;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 100%;
   
}
.payment-value4 {
 
 color: #000;
 font-family: Nunito;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 line-height: 100%;
    
}
.payment-value5 {
 
 color: #000;
 font-family: Nunito;
 font-size: 14px;
 font-style: normal;
 font-weight: 600;
 line-height: 100%;
    
}
.payment-value6 {

 color: #2D93FB;
 font-family: Nunito;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 100%;
    
}
.payment-valuetotal-value {
 
 color: #000;
 font-family: Nunito;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 100%;
    
}
.paymentlabeltotal {
 color: #000;
 font-family: Nunito;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 100%;
    
}
.paymentlabeltotallabel {
 color: #000;
 font-family: Nunito;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 100%;
   
}
.paymentvaluetotal {
 
 color: #000;
 font-family: Nunito;
 font-size: 16px;
 font-style: normal;
 font-weight: 700;
 line-height: 100%;
   
}
.payment-row1 {
    margin-top: 5px;
}
@media (max-width:992px) {
.payment-value1 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; 
}
.payment-value2 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.payment-value3 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.payment-value4 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
        
}
.payment-value5 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;       
}
.payment-valuetotal-value {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
.payment-value6 {
    color: #2D93FB;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
.paymentvaluetotal {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
}
@media (max-width:768px) {
.payment-value1 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
       
}
.payment-value2 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.payment-value3 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.payment-value4 {
    width: 58px;
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.payment-value5 {
    color: #000;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.payment-valuetotal-value {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

}
.payment-value6 {

    color: #2D93FB;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
.paymentvaluetotal {
    color: #000;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
        
}

.test {
        background-color: rgba(196, 196, 196);
}