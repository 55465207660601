input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: black !important; /* Adjust text color */
}

.root-div {
  background: linear-gradient(334.24deg, #7fb800 -9.84%, #0f9b0f 121.28%);
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  float: right;
  padding: 40px;
  margin-left: 50px;
}

.smallscreen-logo-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.smallscreen-logo {
  max-width: 100%;
  max-height: 100%;
  width: 200px;
  height: 46px;
}

.nologin-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nologin-link-text {
  text-align: center;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  color: rgba(234, 246, 225, 1);
  line-height: 33px;
  padding-bottom: 50px;
}

.googlemap {
  width: 100%;
}

.locationtextfield {
  background: #ffffff;
  border: 1px solid #e2f6b9;
  border-radius: 30px;
  height: 56px;
  width: 100%;
  display: flex;
  color: var(--mainColor);
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.locationicon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10%;
  color: #7fb800;
  padding-right: 10px;
  cursor: pointer;
  animation: blink 3s ease-in-out infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.textfield {
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.textfield input {
  width: 100%;
  border: none;
}

.textfield input:focus {
  width: 100%;
  border: none;
  outline: none;
  font-family: "Nunito", sans-serif;
}

.textfield input ::placeholder {
  letter-spacing: 1px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito", sans-serif;
  opacity: 1;
  /* Firefox */
}

.textfield input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito", sans-serif;
}

.textfield input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito", sans-serif;
}

.divider {
  opacity: 0.3;
  border: 1px solid #000000;
  height: 50%;
}

.button-div {
  padding-top: 30px;
  width: 100%;
  position: relative;
}

.continuebutton {
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  background: linear-gradient(180deg, #85be49 0%, #69a85c 100%);
  border-radius: 30px;
  height: 56px;
  border: none;
  color: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
}

.continuebutton:hover {
  background: linear-gradient(180deg, #69a85c 0%, #85be49 100%);
}

.link-form {
  /* display: flex; */
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  color: #eaf6e1;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
}

.link-text {
  font-weight: 800;
  cursor: pointer;
}

.link-text:hover {
  color: rgba(29, 77, 79, 1);
}

/* LOGIN_FORM */

.container-div {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
  border-radius: 10px;
}

.forms-container-div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  /* transform: translate(-50%, -50%); */
  left: 75%;
  width: 50%;
  transition: ease;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding-bottom:  20px;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.2rem;
}

.field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 500;
  font-size: 1.2rem;
  color: #333;
}

.field input:valid {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 500;
  font-size: 1.2rem;
  color: #333;
}

.field input:focus {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 500;
  font-size: 1.2rem;
  color: #333;
}

.field input:active {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 500;
  font-size: 1.2rem;
  color: #333;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 500;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btn {
  width: 100%;
  background: linear-gradient(180deg, #85be49 0%, #69a85c 100%);
  border: none;
  outline: none;
  height: 46px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn-outline {
  width: 100%;
  background: #fff;
  border: 1px solid #69a85c;
  outline: none;
  height: 46px;
  border-radius: 49px;
  color: #69a85c;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

::selection {
  background: #fa4299;
  color: #fff;
}

.wrapper {
  overflow: hidden;
  width: 100%;
  background: #fff;
  /* padding: 30px; */
  border-radius: 10px;
}

.wrapper .title-text {
  display: flex;
  width: 200%;
}

.wrapper .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 60px;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #69a85c;
  border-radius: 5px;
}

.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  transition: all 0.6s ease;
}

.slide-controls label.signup {
  color: #69a85c;
}

.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  /* border-radius: 5px; */
  background: -webkit-linear-gradient(180deg, #85be49 0%, #69a85c 100%);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"] {
  display: none;
}

#signup:checked ~ .slider-tab {
  left: 50%;
}

#signup:checked ~ label.signup {
  color: #fff;
  cursor: default;
  user-select: none;
}

#signup:checked ~ label.login {
  color: #69a85c;
}

#login:checked ~ label.signup {
  color: #69a85c;
}

#login:checked ~ label.login {
  cursor: default;
  user-select: none;
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}

.form-container .form-inner {
  display: flex;
  width: 200%;
}

.home-login-form {
  width: 50%;
  /* display: flex; */
  float: left;
  padding-top: 35px;
  padding-bottom: 35px;
  transition: all 0.6s ease-out;
}

.signup-form {
  width: 50%;
  /* display: flex; */
  float: left;
  padding-top: 35px;
  padding-bottom: 35px;
  transition: all 0.6s ease-out;
}

.signup {
  width: 100%;
  display: flex;
}

.login-form {
  width: 100%;
  display: flex;
  background-color: #fff;
}

.login-form-div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-div form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-div .form-section {
  width: 100%;
}

.login-button-div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopicon-div {
  padding-bottom: 15px;
}

.shopicon {
  width: 300px;
  height: 80px;
}

.form-div form {
  width: 50%;
  /* transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55); */
}

.solid-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-second-half {
  width: 50%;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.solid-button {
  font-family: "Nunito";
  background: linear-gradient(180deg, #85be49 0%, #69a85c 100%);
  width: 70%;
  color: #fff;
  height: 42px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.outlined-button {
  width: 87%;
  background: #ffffff;
  border: 1px solid #69a85c;
  border-radius: 10px;
  color: #69a85c;
  font-size: 18px;
  font-weight: 700;
  height: 42px;
  cursor: pointer;
}

.loginorsignupbutton {
  height: 45px;
  width: 100%;
  border: 1px solid rgba(137, 199, 74, 1);
  color: rgba(137, 199, 74, 1);
  max-width: 200px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-grow: 1;
  box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
  border-radius: 13px;
  background: #fff;
  padding: 15px;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  justify-content: center;
  text-align: center;
}

.form-heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #69a85c;
  text-align: left;
}

.Welcome-form-heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  color: #69a85c;
  text-align: center;
}
.Sign-up {
  font-weight: 700;
  font-size: 26px;
  line-height: 18px;
  color: #69a85c;
  text-align: center;
}
.signup-sub {
  padding-top: 10px;
  text-align: center;
}
.form-inner form .field {
  width: 100%;
}

.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 5px;
  font-size: 17px;
  transition: all 0.3s ease;
  border: none;
  /* border-bottom: 1px solid #69A85C; */
}

.forgot {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #59626a;
}

.form-inner form .field input:focus {
  border-color: #fc83bb;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}

.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

form .field input:focus::placeholder {
  color: #b3b3b3;
}

.form-inner form .pass-link {
  margin-top: 5px;
}

.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
}

.form-inner form .pass-link,
.form-inner form .signup-link a {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #59626a;
  text-decoration: none;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.form-inner form .forgotpass-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #59626a;
  text-decoration: none;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.input-label {
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #59626a;
}

.input-label-div {
  padding-top: 30px;
  padding-bottom: 18px;
}

.divider-div {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #69a85c;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}

form .btn {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

form .btn .btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(
    right,
    #a445b2,
    #fa4299,
    #a445b2,
    #fa4299
  );
  border-radius: 5px;
  transition: all 0.4s ease;
}

form .btn:hover .btn-layer {
  left: 0;
}

form .btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.btn:hover {
  background-color: #4d84e2;
}

.panels-container-div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container-div:before {
  content: "";
  position: absolute;
  height: 1700px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(334.24deg, #7fb800 -9.84%, #0f9b0f 121.28%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container-div.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container-div.sign-up-mode .left-panel .image,
.container-div.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container-div.sign-up-mode .signin-signup {
  left: 25%;
}

.container-div.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container-div.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container-div.sign-up-mode .right-panel .image,
.container-div.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container-div.sign-up-mode .left-panel {
  pointer-events: none;
}

.container-div.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .form-inner form .field input {
    font-size: 16px;
  }
  .solid-button {
    font-family: "Nunito";
    background: linear-gradient(180deg, #85be49 0%, #69a85c 100%);
    width: 100%;
    color: #fff;
    height: 42px;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 700;
    border: none;
    cursor: pointer;
  }
  .container-div {
    min-height: 800px;
    height: 100vh;
  }

  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container-div.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container-div {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container-div:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container-div.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container-div.sign-up-mode .left-panel .image,
  .container-div.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container-div.sign-up-mode .right-panel .image,
  .container-div.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container-div.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
  .root-div {
    background: linear-gradient(334.24deg, #7fb800 -9.84%, #0f9b0f 121.28%);
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    float: right;
    padding: 50px 40px;
    margin-left: 50px;
  }
}

.nearshopz-logo {
  max-width: 100%;
  max-height: 100%;
  width: 220px;
  height: 38px;
}
@media (max-width: 570px) {
  .home-login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .signup-form {
    padding: 30px;
  }

  .outlined-button {
    text-decoration: underline;
    border: none;
  }
  .Welcome-form-heading {
    font-weight: 400;
    font-size: 20px;
  }
  .Sign-up {
    /* font-weight: 400; */
    font-size: 22px;
  }
  form {
    padding: 0px;
  }

  .image {
    display: none;
  }

  .panel .content {
    padding: 0.5rem 1rem;
  }

  .container-div {
    padding: 1.5rem;
  }

  .container-div:before {
    bottom: 72%;
    left: 50%;
  }

  .locationicon {
    color: #7fb800;
  }

  .container-div.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }

  .login-form {
    width: 200%;
    display: flex;
    flex-direction: column;
  }

  .form-inner form .field input {
    font-size: 14px;
  }

  .form-second-half {
    padding-top: 30px;
  }
  .root-div {
    padding: 20px;
  }
}

.suggestions-div {
  /* position: absolute;
  z-index: 20; */
  background: #fff;
  color: #000000;
  padding-left: 15px;
  border-radius: 13px;
  /* margin-top: 70px; */
  width: inherit;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.centerDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.EyeButton {
  border: none;
  background: none;
  cursor: pointer;
}

@media (max-width: 375px) {
  .outlined-button {
    font-size: 14px;
  }
  .solid-button {
    height: 35px;
    font-size: 16px;
  }
  .input-label-div {
    padding-top: 30px;
    padding-bottom: 15px;
  }

  .signup-form {
    padding: 30px;
  }
  .Welcome-form-heading {
    font-size: 18px;
  }
  .Sign-up {
    font-size: 20px;
  }
  .root-div {
    padding: 10px;
  }
  .link-form {
    font-size: 14px;
  }
}
