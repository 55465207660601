.light {
  --mainColor: rgba(127, 184, 0, 1);
  --hoverColor: rgba(29, 77, 79, 1);
  --backgroundColor: rgba(247, 250, 255, 255);
  --darkOne: rgba(29, 77, 79, 1);
  --darkTwo: #45424b;
  --lightOne: #919191;
  --lightTwo: #aaa;
}

.right {
  float: right;
  margin-left: 30px;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.nearshopzlogo {
  width: 243.13px;
  height: 60px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* img {
    width: 100%;
} */

a {
  text-decoration: none;
}

.big-wrapper {
  position: relative;
  padding: 1.7rem 0 2rem;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: rgba(234, 246, 225, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  overflow-x: hidden;
}

.containerdiv {
  position: relative;
  max-width: 81rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 3rem;
  z-index: 10;
}

header {
  position: relative;
  z-index: 70;
}

header .containerdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  display: none;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 40px;
  justify-content: left;
  text-align: left;
  width: 100%;
}

.logo img {
  width: 243.14px;
  margin-right: 0.6rem;
  margin-top: -0.6rem;
}

.logo h3 {
  color: var(--darkTwo);
  font-size: 1.55rem;
  line-height: 1.2;
  font-weight: 700;
}

.links ul {
  display: flex;
  list-style: none;
  align-items: center;
}

.links a {
  color: var(--lightTwo);
  margin-left: 4.5rem;
  display: inline-block;
  transition: 0.3s;
}

.links a:hover {
  color: var(--hoverColor);
  transform: scale(1.05);
}

.btn {
  display: inline-block;
  padding: 0.9rem 1.9rem;
  color: #fff !important;
  background-color: var(--mainColor);
  border-radius: 16px;
  text-transform: capitalize;
  transition: 0.3s;
}

.btn:hover {
  background-color: var(--hoverColor);
  transform: scale(1) !important;
}

.hamburger-menu {
  position: relative;
  z-index: 99;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}

.hamburger-menu .bar {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: var(--darkTwo);
  border-radius: 3px;
  transition: 0.5s;
}

.bar::before,
.bar::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--darkTwo);
  border-radius: 3px;
  transition: 0.5s;
}

.bar::before {
  transform: translateY(-8px);
}

.bar::after {
  transform: translateY(8px);
}

.big-wrapper.active .hamburger-menu .bar {
  background-color: transparent;
}

.big-wrapper.active .bar::before {
  transform: translateY(0) rotate(-45deg);
}

.big-wrapper.active .bar::after {
  transform: translateY(0) rotate(45deg);
}

.showcase-area .containerdiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
}

.big-title {
  width: auto;
  white-space: nowrap;
}
.big-title h1 {
  font-size: 64px;
  color: var(--darkOne);
  text-transform: capitalize;
  line-height: 1.4;
}

.shopsnearby {
  color: var(--mainColor);
}

.oneatatime {
  color: var(--mainColor);
}

.locationtextfield {
  background: #ffffff;
  border: 1px solid #e2f6b9;
  border-radius: 30px;
  height: 56px;
  width: 100%;
  display: flex;
  color: var(--mainColor);
  display: flex;
  align-items: center;
}

.locationicon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10%;
  color: var(--mainColor);
}

.textfield {
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.divider {
  opacity: 0.3;
  border: 1px solid #000000;
  height: 50%;
  /* transform: rotate(90deg); */
}

.text {
  color: var(--darkOne);
  font-size: 1.3rem;
  margin: 1.9rem 0 2.5rem;
  max-width: 700px;
  line-height: 2.3;
  font-weight: 500;
  padding-left: 10px;
}

.showcase-area .btn {
  box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.05);
}

.person {
  width: 123%;
  transform: translate(15%, 25px);
}

.toggle-btn {
  display: inline-block;
  border: none;
  background: var(--darkTwo);
  color: var(--backgroundColor);
  outline: none;
  cursor: pointer;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  font-size: 1.1rem;
  transition: 0.3s;
}

.toggle-btn i {
  line-height: 39px;
}

.toggle-btn:hover {
  background: var(--mainColor);
}

.big-wrapper.light .toggle-btn i:last-child {
  display: none;
}

.big-wrapper.light .toggle-btn i:first-child {
  display: block;
}

.big-wrapper.dark .toggle-btn i:last-child {
  display: block;
}

.big-wrapper.dark .toggle-btn i:first-child {
  display: none;
}

.shape {
  position: absolute;
  z-index: 0;
  width: 210px;
  opacity: 1;
}

.copy {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  animation: appear 1s 1 both;
}

@keyframes appear {
  0% {
    clip-path: circle(30% at -25% -25%);
  }

  100% {
    clip-path: circle(150% at 0 0);
  }
}

@media screen and (max-width: 870px) {
  .hamburger-menu {
    display: flex;
  }

  .links {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 450px;
    width: 100%;
    height: 100%;
    background-color: var(--mainColor);
    z-index: 95;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: 0.5s;
  }

  .links ul {
    flex-direction: column;
  }

  .links a {
    color: #fff;
    margin-left: 0;
    padding: 2rem 0;
  }

  .links .btn {
    background: none;
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
  }

  .big-wrapper.active .links {
    transform: translateX(0);
    box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.4);
  }

  .big-wrapper.active .overlay {
    pointer-events: all;
    opacity: 1;
  }

  .showcase-area {
    /* padding: 2.5rem 0; */
    max-width: 700px;
    margin: 0 auto;
  }

  .showcase-area .containerdiv {
    grid-template-columns: 1fr;
    justify-content: center;
    grid-gap: 2rem;
  }

  .big-title {
    font-size: 64px;
    white-space: break-spaces;
  }

  .text {
    font-size: 0.95rem;
    margin: 1.4rem 0 1.5rem;
  }

  .person {
    width: 100%;
    transform: none;
  }

  .logo h3 {
    font-size: 1.25rem;
  }

  .shape {
    position: absolute;
    width: 210px;
  }
}




@media screen and (max-width: 1024px) {
  .nearshopzlogo {
    width: 200px;
    height: auto;
  }

  .big-title h1 {
    font-size: 48px;
  }

  .containerdiv {
    padding: 0 2rem;
  }

  .big-wrapper {
    padding: 1rem 0;
  }

  .showcase-area .containerdiv {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }

  .text {
    font-size: 1.1rem;
    margin: 1.5rem 0;
  }

  .showcase-area .btn {
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
  }
  .left {
    width: 70%;
    margin: 0 auto;
  }

  .right {
    width: 70%;
    margin: 0 auto;
  }

  .logo {
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    text-align: center;
  }
  .shape {
    width: 180px;
  }
  .big-title{
    display: none;
  }
  .text{
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .right {
    margin-left: 0;
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 470px) {
  .containerdiv {
    padding: 0 1.5rem;
  }

  .big-title {
    font-size: 64px;
    white-space: break-spaces;
  }

  .text {
    margin: 1.1rem 0 1.5rem;
  }

  .showcase-area .btn {
    font-size: 0.8rem;
  }
  .right {
    margin-left: 0;
    width: 100%;
    left: 0;
  }
}

@media (max-width: 376px){
  .locationtextfield{
    height: 40px;
  }
  .continuebutton{
    height: 40px;
  }
  
 
}