*{
    box-sizing: border-box;
}
.grid-thirty-p{
    padding-top: 10px;
}
.css-fkh5li-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
    background: #2D93FB;

}
.loader-image {
    width: 400px;            /* Adjust size as needed */
    height: 400px;           /* Adjust size as needed */
  }