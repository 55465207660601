.delivery-instructions-heading{
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.character-counter {
  position: relative;
  
}

.custom-textarea {
  width: 100%;
  height: 100px;

  resize: none;
  
  border-radius: 5px;
  border: 0.5px solid rgba(103, 104, 109, 0.50);
  padding: 10px;
}

.char-count {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: #67686D;
  font-size: 11px;
  font-weight: 500;
}