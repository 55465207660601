.ModalContent{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #061303;
    opacity: 0.7;
}
.css-14yr603-MuiStepContent-root{
    padding-right: 0px !important
}