body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(247, 250, 255, 255);
  touch-action: pinch-zoom double-tap-zoom none;
  width: 100vw;
}

code {
  font-family: 'Nunito', sans-serif;
}

input[type=text]:focus {
  border: none; 
  outline: none;
}

@media (min-width: 1200px) {
  .css-ykrfwo-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
      width: 500px !important;
  }
}

@media (min-width: 1200px) {
  .css-1k7vl8s .MuiDrawer-paper {
      width: 500px !important;
  }
}

/* .global-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999; 
}

.global-loader img {
  width: 400px; 
  height: 400px;
} */
