.product-small-img{
    display: flex;
    float:left;
    padding-top: 30px;
}
.product-small-img-container{
    display: flex;
    float:left;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 25%;
    height:70px;
    margin-right: 6px;
}
.product-small-img img{
height:70px;
width:70px;
margin:0px 5px;
display: block;
cursor:pointer;
opacity:0.6;

}
.product-small-img img:hover{
    opacity:1;
    }
.img-container img{
    width:350px;
    height:300px;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}
.img-container{
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
}
.slick-prev:before,
.slick-next:before {
  color: #EAEEF7;
}
@media (max-width:450px){
    .img-container img{
        width:150px;
        height: 140px;
    }
}