.instructions-section {
  /* width: 332px; */
  width:100%;
  height: 227px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.10);
}
  
  
  .cookingInsHead {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    text-transform: capitalize;
    padding-bottom: 39px;
    padding-left: 41px;
    margin-bottom: -68px;
    margin-top: -38px;
   
}
   
  
  
  
  
  .save-button {
    width: 100px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 26.5px;
    background: #6AA92A;
    border: azure;
    margin-left: 1110px;
   
  }
   
 #cooking-instructions{

    width: 100%;
    height: 63px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.5px solid rgba(103, 104, 109, 0.50);
   
    
}

 

.icon{
  width: 22px;
  height: 17.875px;
  flex-shrink: 0;
  fill: #000;
  padding-right: 23px;
  padding-bottom: 41.13px;
  
  
}

