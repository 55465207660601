.notification-button {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  background-color: transparent;
  letter-spacing: 1px;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}
.notification-box {
  height: 100%;
  width: 100%;
  background-color: rgba(234, 238, 247, 0.3);
}
.productname-height {
  width: 100%;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 1.5em;
  height: 100%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.shoppingcart-01 {
  color: #000000;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}
.text-shopping {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
}

@media (max-width: 1024px) and (min-width: 768px) {
    .text-shopping {
      justify-content: center;
    }
}
@media (max-width: 768px) and (min-width: 426px) {
  .text-shopping {
    justify-content: center;
  }
  .productname-height {
    height: 100%;
  }
}
@media (max-width: 426px) {
  .productname-height {
    height: 100%;
  }
  .shoppingcart-01 {
    font-size: 18px;
  }

}

@media (max-width: 700px) {
  .notification-button {
    display: flex;
    /* flex-direction: row; */
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    background-color: transparent;
  }
}
.Center {
  display: flex;
  margin: 0 auto;
  align-items: center;
}
