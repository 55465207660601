.main-div
{
    height: 100vh;
    width: 100%;
    padding: 50px;
}

.pagenotfound-div
{
    background: #fff;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.pagenotfound-image
{
    width: 300px;
    height: 300px;
    max-width: 100%;
    max-height: 100%;
}

.pagenotfound-heading
{
    font-size: 34px;
    font-weight: 600;
}

.pagenotfound-desc
{
    color: #061303;
    opacity: 0.5;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 15px;
}

.gotohome-button
{
    background: #89C74A;
    border-radius: 26.5px;
    width: 25%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 56px;
    cursor: pointer;
}