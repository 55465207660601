.delivery-tip-container {
    display: flex;
    justify-content: space-between;
    gap: 14px;
}

.delivery-tip-box {
    width: 100%;
    height: 26px;
    border-radius: 5.431px;
    border: 0.5px solid #D0CECE;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #030303;
    font-size: 11.663px;
    font-weight: 600;
    line-height: 21.382px;
    cursor: pointer;
}

.delivery-tip-box-selected {
    width: 100%;
    height: 26px;
    border-radius: 5.431px;
    border: 0.5px solid #6AA92A;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #6AA92A;
    font-size: 11.663px;
    font-weight: 600;
    line-height: 21.382px;
    cursor: pointer;
}

.most-tipped-delivery-tip {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40px;
    border-radius: 5.431px;
    border-top: 0.5px solid rgba(0, 0, 0, 0.08);
    border-left: 0.5px solid rgba(0, 0, 0, 0.08);
    border-right: 0.5px solid rgba(0, 0, 0, 0.08);
    border-bottom: transparent;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #030303;
    font-size: 11.663px;
    font-weight: 600;
    line-height: 21.382px;
    cursor: pointer;
}

.most-tipped-text-box {
    border-radius: 0px 0px 5.431px 5.431px;
    background: linear-gradient(93deg, #FFD026 2.55%, #FF8E26 145.24%);
    color: #FFF;
    font-size: 9px;
    font-weight: 700;
    line-height: 21.382px;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 15px;
}