.container {
  width: 100%;
  /* padding-top: 1.25rem;
  padding-bottom: 1.25rem; */
  padding: 1.25rem 1rem;
  justify-content: center;
  align-items: center;
  text-align: center !important;
}

.addresspadding {
  padding: 1.25rem 2rem; /* Consistent padding for address section */
}
.para {
  font-size: 1.125rem;
  line-height: 2.5rem;
  color: #b2b2b2;
}
.seperator {
  border-top: 1px solid #e1e3ea;
}

.clr,
.clear {
  clear: both;
}

.socialLinks {
  list-style: none;
  /* float: left !important; */
}

.socialLinks > li {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  /* *display: inline;
    *vertical-align: auto; */
  margin: 10px 20px 10px 0;
}

.socialLinks > li > a {
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
.mainHeading {
    font-size: 40px;
    font-weight: 600;
    color: #424242;
    padding-top: 20px;
    padding-bottom: 20px;
  }

a {
  color: #000;
  text-decoration: none;
}

.WrapperTop {
  background-color: white;
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
  color: black;
}

.centerItems {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: block;
}

.ImageGrid {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .logo {
    width: 12.5rem;
    height: 5.5rem;
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    margin: auto;
} */

.ContactHeading {
  padding: "15px";
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
}

.middle-right-section {
  padding-bottom: 20px;
  text-align: center;
  align-items: center;
  display: center;
  justify-content: center;
}

/* .addresspadding {
  padding: 1.25rem 1rem;
} */

.address-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  color: #b2b2b2;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  padding-top: 1.875rem;
}

.address {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  color: #b2b2b2;
  padding-top: 1rem;
}

.contact {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #b2b2b2;
  padding-top: 1.25rem;
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center !important;
  }
  .para {
    font-size: 13px;
    line-height: 2rem;
    color: #b2b2b2;
  }
  .mb {
    list-style: none;
    text-align: center !important;
    float: none !important;
  }
  .ph {
    margin-bottom: 15px !important;
  }
  .address-heading {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .address {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .contact {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .container {
    padding: 1.25rem 0.75rem;
  }

  .addresspadding {
    padding: 1rem 0.75rem;
  }
}

@media only screen and (max-width: 576px) {
    .mainHeading {
        font-size: 24px;
    }
  .address-heading {
    font-size: 1.125rem;
    padding-top: 0px;
  }

  .address {
    font-size: 0.875rem;
  }

  .contact {
    font-size: 0.75rem;
  }
  .container {
    padding: 1.25rem 0.5rem; /* More compact padding for extra small screens */
  }

  .addresspadding {
    padding: 0.75rem 0.5rem; /* Reduced padding for extra small screens */
  }
}

@media only screen and (max-width: 376px) {
    .mainHeading {
        font-size: 20px;
    }
  .address {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container,
  .addresspadding {
    padding: 1rem 0.5rem;
  }
}
