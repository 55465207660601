.TopHeader .InputBox ::placeholder {
    color: rgba(255, 255, 255, 1);
    opacity: 1; /* Firefox */
  }
  
  .TopHeader .InputBox :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 1);
  }
  
  .TopHeader .InputBox ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(255, 255, 255, 1);
  }

.location-root-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-box {
    height: 45px;
    width: 195px;
    flex-grow: 1;
    max-width: 200px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-grow: 1;
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
    border-radius: 13px;
    background-color: rgba(0, 0, 0, 0.04);
    padding: 5px;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.location-box1 {
  height: 45px;
  width: 250px;
  flex-grow: 1;
  max-width: 200px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-grow: 1;
  box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
  border-radius: 13px;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 5px;
  align-items: center;
  position: relative;
  cursor: pointer;
}

@media (min-width: 1023px) {

  .location-box{
      width: 250px;
      max-width: 200px;
      flex-grow: 3;
  }
  .location-box1{
    width: 350px;
    max-width: 400px;
    /* flex-grow: 3; */
} 

}

@media (max-width: 1440px){
  .location-box1{
    width: 250px;
    max-width: 250px;
    /* flex-grow: 3; */
} 
}
.location-xs{
    height: 15px;
    background: transparent;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.location-box-store {
    height: 45px;
    width: 100%;
    border: 1px solid rgba(137, 199, 74, 1);
    color: rgba(137, 199, 74, 1);
    max-width: 200px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-grow: 1;
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
    border-radius: 13px;
    background: #fff;
    padding: 15px;
    align-items: center;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.location-name {
    width: 100%;
    border: none;
    outline: none;
    padding-left: 8px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
}

.location-first-name
{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
}

.location-name-xs
{
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.location-last-name
{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
    color: rgba(255,255,255,0.4);
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
}

.TopHeader .InputBox .searchIcon {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 9px;
    width: 35px;
    height: 35px;
    color: #fff;
}

input:focus
{
    border: none;
    outline: none;
}

@media (max-width: 700px) {

    .TopHeader .InputBox {
        width: 100%;
    }
}

.location-navbar {
    display: flex;
    width: 100%;
    background: #fff;
    box-shadow: 0px -4px 4px #EAEEF7;
    height: 51px;
    align-items: center;
    padding: 20px;
    position: fixed;
    z-index: 200;
}

.location-icon-div {
    width: auto;
    font-size: 14px;
    color: #89C74A;
    font-weight: 600;
    padding-right: 10px;
}

.delivery-location {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 100%;
    overflow: hidden;
    color: rgba(6, 19, 3, 0.7);
    font-size: 14px;
    white-space: nowrap;
    box-sizing: border-box;
}

.delivery-location p {
    animation: scroll-animation 8s linear infinite;
    animation-fill-mode: forwards;
    animation-delay: 0ms;
    white-space: nowrap;
    display: inline-block;
  }

  /* .delivery-location p:first-child {
    animation-delay: 10s;
  }
  
  .delivery-location p + p {
    animation-delay: 3s;
  } */
  
  @keyframes scroll-animation {
    0% { 
      transform: translateX(100%); 
    }
    100% { 
      transform: translateX(-100%); 
    }
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(-100%);
        }
  }
  

.change-location-button-div {
    float: right;
    display: flex;
    justify-content: right;
    align-items: center;
    width: auto;
    padding-left: 10px;
    width: auto;
}

.change-location-button {
    background: #89C74A;
    border-radius: 5px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    border: none;
    height: 26px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    white-space: nowrap;
    min-width: 120px;
    width: auto;
}

@media (min-width: 768px) {
    .change-location-button {
      width: auto;
    }
  }