.explore-button{
    background-image: linear-gradient(334.24deg, #7FB800 -9.84%, #0F9B0F 121.28%);
    border: 1px solid #EAEEF7;
    border-radius: 29px;
    height: 52px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#ffffff;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    background-size: 300% 100%;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.explore-button:hover{
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    width:51%;
}

.explore-button-small-screen{
    background-image: linear-gradient(334.24deg, #7FB800 -9.84%, #0F9B0F 121.28%);
    border: 1px solid #EAEEF7;
    border-radius: 29px;
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#ffffff;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
}
@media screen and (max-width: 1024px) {
    .explore-button{
        width: 100%;
    }
}
@media (max-Width:376px){
   .explore-button-small-screen{
    height: 40px;
    font-size: 15px;
    line-height: 21px;
   }
}
