.logo-image{
    width:15.625rem;
    height: 3.75rem;
}
.Navbar{
    /* height: 68px; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    padding-top: 1.25rem;
    /* position: absolute; */
    z-index: 6.25rem;
    width: 100%;
    padding-bottom: 1.25rem;
}
.headerButton{
    border:none;
    background-color:white;
    cursor: pointer;  
}
@media(max-width:599px){
    .logo-image{
        width:160px;
        height: 50px;
    }
}