.mobStore-name {
    font-style: normal;
    font-weight: 700;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Card-Image {
    width: 85px;
    height: 85px;
    border-radius: 10px;
}

.store-image-div {
    /* width: 85px;
    height: 130px; */
    padding-left:15px;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
  
}
.css-19mpqc3 {
    min-height: 72px;
    display: block;
}

.css-b8kzem {
    padding-top: 12px;
}

.css-1c4xlbn {
    height: 100%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: min-content 1fr max-content;
    grid-template-rows: 1fr min-content;
    grid-template-areas:
        "image center right"
        "footer footer footer";
    position: relative;
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding: 12px;
    padding: 0;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}

a {
    background-color: transparent;
}

.css-l399wx {
    grid-area: image;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 12px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.css-l399wx img {
    max-width: unset;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #e8e9eb;
}

.css-798uwr {
    display: inline-block;
    max-width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

img {
    border-style: none;
}

.css-szvrfi {
    grid-area: center;
    display: block;
}

.css-1270xj {
    display: block;
    color: #343538;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    display: inline;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
}

.css-7j45et-StoreCardAttributeETA {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #343538;
}

.css-fv3lde {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.css-1b5swo5 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
}

.css-14c7ris {
    grid-area: right;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    margin-left: auto;
}

.css-gb3afq {
    -webkit-flex-basis: 24px;
    -ms-flex-preferred-size: 24px;
    flex-basis: 24px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
}
@media (max-width:450px){
    .Card-Image{
        width: 65px;
        height: 65px;
    }
}