.root1 {
    width: 100%;
    background: transparent;
}
.dashline{
    width: 100%;
    background: transparent;
    border-bottom: 1px dashed #000;
    padding: 5px 0 5px 0;
    margin-bottom:30px;
    box-sizing: border-box;
}

.drawerCloseIcon {
    text-align: right;
    float: right;
}

.couponHeader {
    border-radius: 10px;
    border: 1px solid #CFE4B2;
    background: rgba(0, 0, 0, 1);
    width: 100%;
    position: relative;
    z-index: 1;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 56px;
    padding-right: 34px;
}

.leftCircle {
    width: 33px;
    height: 30px;
    position: absolute;
    z-index: 2;
    background-color: #fff;
    border-radius: 50%;
    top: 45px;
    left: -5px;
}

.rightCircle {
    width: 33px;
    height: 30px;
    position: absolute;
    z-index: 2;
    background-color: #fff;
    border-radius: 50%;
    top: 45px;
    right: -20px;
}

.couponName {
    color: #58B908;
    text-align: left;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 17.455px;
    font-style: normal;
    font-weight: 700;
    line-height: 32.182px;
    letter-spacing: 3.242px;
    text-transform: capitalize;
}

.offer {
    color: #B6B6B6;
    text-align: left;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    font-family: Nunito;
    text-transform: capitalize;
}

.description1 {
    color: #FFFFFF;
    text-align: left;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: capitalize;
}

.offerPercentage {
    color: #B6B6B6;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-transform: capitalize;
    transform: rotate(90deg);
    transform-origin: bottom;
    display: inline-block;
    float: right;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    right: 25px;
    top: 50px;
}

.couponDescriptionHeading {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 14px;
}

.couponDescription {
    padding-top: 5px;
    color: #747474;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.apply-coupon
{
    border-radius: 10px;
    border: 1px dashed rgba(0, 0, 0, 0.60);
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 17px;
    padding-bottom: 17px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.apply-coupon-text{
    padding-left: 10px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    width: 100%;
}
.apply-coupon-text1{
    padding-left: 75px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    width: 100%;
}

.apply-coupon-arrow {
    text-align: right;
    float: right;
    width: 100%;
}

.coupon-text-field {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.04);
    /* padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px; */
    height: 50px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    justify-content: center;
    display: flex;
    background-color: transparent;
}

.available-coupons-text {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.coupon-input-span {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(0,0,0,0.8);
}

.coupon-input {
    border: none;
    width: 100%;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.coupon-apply-button {
    border: 1px solid #85BE49;
    border-radius: 0px 10px 10px 0px;
    background: #69A85C;
    box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.04);
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.coupons-container {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.04);
    padding: 18px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.couponApplyButton {
    border-radius: 10px;
    background: #6AA92A;
    width: 125px;
    height: 35.714px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    cursor: pointer;
}

.moreButtonDiv {
    display: flex;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 19px;
    width: fit-content;
    cursor: pointer;
}

.moreText {
    color: #061303;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    text-decoration-line: underline;
    padding-left: 5px;
}

.couponConditionslist {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    text-transform: capitalize;
    padding-top: 20px;
    padding-left: 14px;
    padding-bottom: 20px;
}

.applied-coupon-name {
    color: #000;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-weight: 500;
    line-height: 30.438px;
}

.applied-coupon-savings {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 30.438px; 
}

.applied-coupon-text {
    color: #7C6868;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 30.438px; 
}

.thanks-text {
    color: #58B908;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 30.438px; 
    cursor: pointer;
}
.coupon-error-message{
    color:red
}


.Dashedline{
        margin: 20px 0px 20px 0px;
        border: none;
        height: 1px;
        background: #000;
        background: repeating-linear-gradient(90deg,#000,#000 6px,transparent 6px,transparent 12px);
      
}

.circle1 {
    position: absolute;
    left: 0;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background: rgb(244, 245, 249); */
    overflow: hidden;

}

.circle2{
    position: absolute;
    right: 0;
    transform: translate(50%, -50%);
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background: rgb(244, 245, 249);  */
   overflow: hidden;
    
}


.circle1
{
    left: -12px;
    top: 80px;
}

.circle2
{
    right: -12px;
    top: 80px;
}

.half1{
    display: flex;
    height: 100%;
    width: 100%;
}

.content1{
    height:100%;
    width:100%;
}

.half2{
    display: flex;
    height: 100%;
    width: 100%;
}

.content2{
    height:100%;
    width:100%;
}
