*{
    box-sizing: border-box;
    
}
.wid-100{
    width: 100%;
}
.placeholder-transition-0 {
    animation: slideUp 0.5s forwards;
  }
  
  .placeholder-transition-1 {
    animation: slideUp 0.5s forwards;
  }
  
  .placeholder-transition-2 {
    animation: slideUp 0.5s forwards;
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(20px); /* Initial position */
      opacity: 0; /* Initial opacity */
    }
    100% {
      transform: translateY(0); /* Final position */
      opacity: 1; /* Final opacity */
    }
  }
  
.TopHeader .InputBox ::placeholder {
    color: rgba(255, 255, 255, 1);
    opacity: 1; /* Firefox */
  }
  
  .TopHeader .InputBox :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 1);
  }
  
  .TopHeader .InputBox ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(255, 255, 255, 1);
  }

.search-container {
    width: 100%;
}

.TopHeader .InputBox {
    height: 45px;
    width: 100%;
    flex-grow: 1;
    /* max-width: 600px; */
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-grow: 1;
    box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.04);
    border-radius: 13px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    align-items: center;
    position: relative;
}

.TopHeader .InputBox input {
    width: 100%;
    border: none;
    outline: none;
    padding: 8px 0px 8px 15px;
    background: transparent;
}

.TopHeader .InputBox .searchIcon {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 9px;
    width: 35px;
    height: 35px;
    color: #fff;
}

.TopHeader .InputBox .SearchResults {
    width: 100%;
    top: 50px;
    left: 0;
    position: absolute;
    background: #fff;
    border-radius: 13px;
    text-align: left;
    z-index: 90;
    padding-bottom: 15px;
    overflow-y: scroll; /* Allow scrolling if results exceed max-height */
    max-height: 70vh; /* Limit maximum height */
    min-height: 50px; /* Minimum height for visual appearance */
}


.TopHeader .InputBox .SearchResults li {
    padding-left: 10px;
    padding-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    cursor: pointer;
    width: 90%;
}
/* .TopHeader .InputBox .SearchResults li .searchresultstext {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
} */
.TopHeader .InputBox .SearchResults li .searchresultstext {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limits text to 3 lines */
    line-clamp: 3; /* Standard property for compatibility */
    -webkit-box-orient: vertical;
    overflow-y: scroll;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}


.TopHeader .InputBox .SearchResults li:hover {
    padding-left: 10px;
    padding-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.03);
}

.TopHeader .InputBox .SearchResults .searchresulticon{
    padding-right: 12px;
    color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 700px) {

    .TopHeader .InputBox {
        width: 100%;
    }
}

.searchimage
{
    width: 40px;
    height: 40px;
    border-radius: 12px;
}
.serach_parent_div{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}
.search_Container{
    width: 100%;
}
.search_Container .header{
   width: 100%;
   display: flex;
   align-items: center;
   gap: 30px;
   padding-bottom: 30px;
   box-sizing: border-box;
}
.search-heading-text{
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    text-align: right;

}

.search_cover{
    width: 68%;
    margin: auto;
}
.search_Input{
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-radius: 18px;
}
.recent_serach_parent{
    width: 100%;
    padding: 20px 0 30px 0;
}
.recent-search-head{
    
    /* opacity: 0px; */
    font-family: Nunito;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}
.recent_serach_flex{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap:20px;
    padding: 10px 0px;
    flex-wrap: nowrap;
}
.recent_serach_child_box{
   width: 175px;
    padding: 13px 16px 13px 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 12px;
    box-sizing: border-box;
    font-family: Nunito;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.porpular_prdts_text_head{
    font-family: Nunito;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    
}
.popular_product_conatiner{
    padding: 10px 0 30px 0;
    box-sizing: border-box;
    overflow-y: scroll;
}
.popular_prdts_div{

    padding-top: 20px;
    width: 200px;
    
}
.prdts_global_div{
    padding-top: 20px;
}
.popular_prdt_child_div{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 0 8px 0;
    box-sizing: border-box;
    /* overflow-y: scroll; */
    
}

.popular_prdt_child_div:hover {
    cursor: pointer;
  }
.matching-reslt-head{
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 30px;
    box-sizing: border-box;
}
.matching_result_conatiner{
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    box-sizing: border-box;
    overflow-y: scroll;
}
.store_conatiner{
    width: 100%;
    border: 1px solid #DFDFDF;
    border-radius: 12px;
    padding: 22px;
    
}
.matching_result_wrapper{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.store_name{
    font-family: Nunito;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
}
.product_details_div{
    display: flex;
    align-Items: center;
    justify-Content: space-between;
    padding-top: 18px;
    padding-bottom: 20px;
    box-sizing: border-box;
    
}
.product_name_prop{
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    white-space: normal;
    word-break: break-word;

}
@media (max-width: 778px) {
    .product_details_div {
        flex-direction: row; /* Stacks content vertically */
        align-items: center; /* Aligns items to the start */
    }

    .product_price {
        font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
        margin-top: 18px; /* Adds spacing between content and price */
    }
    /* .horizontal-scroll1{
        overflow: scroll;
       height: 350px;
    } */
    .search_cover{
        width: 90%;
    }
}


.horizontal-scroll1 {
    width: 100%;
    height: 268px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap:5px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
    /* overflow: hidden; */
} 

@media (max-width: 455px) {

    .search-heading-text{
       text-align: left;
    font-size: 18px;
    }
    .TopHeader .InputBox {
       margin-bottom: 15px;
    }
    .search_Container .header{
        gap: 15px;
        padding-top: 27px;
    }
    .search_cover{
        width: 86%;
    }
    .recent_serach_flex{
        flex-direction: column;
    }
    .recent_serach_child_box{
        width: 100%;
    }
    .popular_prdt_child_div{
        gap:60px;
    }
    .horizontal-scroll1{
        overflow: scroll;
        height: 350px;
    }
    .product_name_prop{
             font-size: 14px;
    }
    .product_details_div {
        flex-direction: column; /* Stacks content vertically */
        align-items: center; /* Aligns items to the start */
    }
} 
@media (min-width: 500px) and (max-width: 800px) {
    .popular_prdt_child_div {
        gap: 40px;
    }
    /* .search_cover{
        width: 88%;
    }
    .horizontal-scroll1{
        overflow: scroll;
        height: 350px;
    } */
     .location-box{
        width: 155px;
     }
     .TopHeader .InputBox input {
        padding: 8px 0px 8px 16px;
    }
}

/*  */
@media (min-width: 1300px) {
    .search_cover{
        padding-top: 70px;
    }
    
}
@media (min-width : 1000px){
    .TopHeader .InputBox input {
        padding: 8px 0px 8px 9px;

    }
    .popular_prdt_child_div{
        gap:30px;
    }
}
 
 