.appStoreIcon
{
    margin-right: 10px;
    cursor: pointer;
    width: 133px;
    height: 43px;
}
.appStoreIcon:hover
{
    border:3px solid #fff;
    border-radius: 10px;
}
.desc_12{
    text-align: justify;
    padding-top: 20px;
    font-size: 17px;
}


@media (max-width:376px){
   .desc_12{
    font-size: 14px;  
   }
   .heading-padding{
    padding-right: 20px;
   }
   .ease-font{
    padding-right: 20px;
   }
}