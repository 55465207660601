.category-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.horizontal-scroll-list::-webkit-scrollbar {
    display: none;
  }
  
.horizontal-scroll-list {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}

.category-container-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: 0.5s all ease-out;
}

.category-padding {
    padding-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-list-div {
    width: 90px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    background: rgba(196, 196, 196, 0.2);
    border-radius: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
}

.category-name-div {
    width: 80px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


@media only screen and (max-width: 767px) {
    .horizontal-scroll-list {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: scroll;
    }
}
/* @media only screen and (max-width: 321px) {
    .horizontal-scroll-list {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: scroll;
    }
} */