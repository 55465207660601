*{
    box-sizing: border-box;
    /* overflow-x: hidden; */
}
.cancelation-conatiner{
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
}
.wrapper-cancelation{
    width: 85%;
    margin: auto;
    padding-top: 120px;
    box-sizing: border-box;
}
.heading-cancelation{
    font-family: Nunito;
    font-size: 28px;
    font-weight: 800;
    line-height: 54.56px;
    text-align: left;

}
.cancelation-text-conatiner{
    padding-top: 20px;
    box-sizing: border-box;
    padding-bottom: 20px;
}
.cancelation-subhead{
    font-family: Nunito;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.74px;
    text-align: left;
    margin-right: 5px;

}
.canacelation-terms-text{
    font-family: Nunito;
    font-size: 18px;
    font-weight: 400;
    line-height: 27.28px;
    text-align: left;

}