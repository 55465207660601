.NotificationHeader {
  background: #ffffff;
  width: 100%;
  max-width: 100%;
  /* min-height: 6.25rem; */
  min-height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 110%;
  color: #061303;
  /* position: fixed; */
  z-index: 20;
  /* padding-left: 20px; */
  height: 100px;
}

.leftarrow {
  background: #eaf6e1;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}

.Content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  height: -1px;
  padding-top: 15px;
  padding-bottom: 2.5rem;
  padding-left: 15px;
  padding-right: 15px;
}
.NotificationContent {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 13px;
  /* min-height: 48rem; */
  min-height: 100%;
  height: auto;
  padding-right: 0.375rem;
}
.NotificationImgContent {
  position: relative;
  /* width: 28.125rem; */
  width: 100%;
  background: #ffffff;
  border-radius: 13px;
  /* min-height: 48rem; */
  min-height: 78vh;
  height: auto;
  padding-right: 0.375rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.NotificationCard {
  display: flex;
  align-items: center;
  height: 6.25rem;
}
.NotificationImage {
  width: 12.5rem;
  height: 15rem;
  /* margin:auto; */
}
.noMessage {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  color: #000000;
}
.noMessages {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
  color: #061303;
  opacity: 0.5;
}
.Message {
  display: flex;
  width: 22rem;
}
.Status {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #061303;
  /* opacity: 0.7; */
}
.SubMessage {
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
  color: rgba(6, 19, 3, 0.7);
  padding-left: 0.5rem;
}
.time {
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 1.2rem;
  color: #061303;
  opacity: 0.3;
  width: 45px;
}
.BlueIndicator {
  width: 1.25rem;
  height: 3.75rem;
  background: rgba(45, 147, 251, 0.5);
  border-radius: 0px 9px 9px 0px;
}
.RedIndicator {
  width: 1.25rem;
  height: 3.75rem;
  background: rgba(234, 67, 53, 0.5);
  border-radius: 0px 9px 9px 0px;
}
.YellowIndicator {
  width: 1.25rem;
  height: 3.75rem;
  background: rgba(255, 217, 81, 0.5);
  border-radius: 0px 9px 9px 0px;
}
.GreenIndicator {
  width: 1.25rem;
  height: 3.75rem;
  background: rgba(133, 190, 73, 0.5);
  border-radius: 0px 9px 9px 0px;
}
.OtherIndicator {
  width: 1.25rem;
  height: 3.75rem;
  background: rgba(222, 156, 230, 0.5);
  border-radius: 0px 9px 9px 0px;
}
.divider-01 {
  width: 100%;
  height: 0rem;
  opacity: 0.7;
  border: 1px solid #eaeef7;
}

.notification-button {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  background-color: transparent;
  letter-spacing: 1px;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}

@media (max-width: 700px) {
  .notification-button {
    display: flex;
    flex-direction: row;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    background-color: transparent;
  }
}

.notokendiv {
  height: 100vh;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.loginpopupdiv {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
