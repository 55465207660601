.root {
    display: flex;
    flex-direction: column;
     padding-left: 15px; 
     padding-right: 15px;
    padding-bottom: 35px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.root1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 35px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.no-stores-div {
    width: 100%;
    min-height: calc(100vh - 145px); /* Adjusts for header or other elements */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #FFFFFF;
    border-radius: 13px;
    flex-direction: column;
    margin: 0 auto; /* Center on the page */
}

.no-stores-div-image {
    width: 250px;
    height: 250px;
}

;

.noMessage {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    color: #000000;
}

.noMessages {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center;
    color: #061303;
    opacity: 0.5;
}

.location-button-div
{
    padding-top: 20px;
}