
  main {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    max-width: 100%;
    margin: 2rem auto;
  }
  
  /* Styling hero */
  .hero {
    max-width: 50rem;
    margin-bottom: 3rem;
  }
  
  /* Styling grid */
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 2rem;
    padding-top: 90px;
    width: 100%;
  }
  
  .grid div {
    background: transparent;
    /* height: 10rem; */
    /* center the text */
    display: flex;
    /* align-items: center;
    justify-content: center; */
  }
  
  .article {
    /* background: #eee; */
    flex-grow: 999;
    flex-basis: 0;
  }
  
  .sticky {
    background: #eee;
    min-height: 28rem;
    border-radius: 13px;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    flex-basis: 300px;
    position: sticky;
    top: 15rem;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .carousel-container {
    display: flex;
    overflow-x: scroll;
    width: 95vw;
  }
  
  .carousel-container::-webkit-scrollbar {
    display: none;
  }
  .carousel-item {
    flex: 0 0 33.33%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto
  }
  
  .active {
    opacity: 1;
    transform: translateX(0);
  }
 @media (max-width: 780px){
  .carousel-container{
    gap:10px;
  }
 } 