.button-replace{
    padding: 10px 50px;
    background: #85BE49;
    border-radius: 10px;
    color: white;
    border: transparent;
    font-weight: 600;
    cursor: pointer;
}
.cart-contains{
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}