body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    display: flex;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    overflow-x: hidden;
}
.about-location{
    padding-top: 25px;
    padding-left: 30px;
}

@media only screen and (max-width: 767px) {
    .container {
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center !important;
        display: flex;
    }
    .about-location{
       padding-left: 10px;
       padding-top: 10px;
    }
}

.para {
    font-size:24px;
    line-height: 2.5rem;
    color: rgba(29, 77, 79, 1);
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    z-index: 1;
    text-align: justify;
}

.download-app-section
{
    /* position: absolute; */
    z-index: 2;
}

@media only screen and (max-width: 767px) {
    .para {
        font-size: 13px;
        line-height: 2rem;
        color: #b2b2b2;
    }
}

.seperator {
    border-top: 1px solid #E1E3EA;
}

.clr,
.clear {
    clear: both;
}

.socialLinks {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.socialLinks>li {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    display: inline;
    vertical-align: auto;
    margin: 10px 20px 10px 0;
}

.socialLinks>li>a {
    display: block;
    font-size: 1rem;
    line-height: 1.25rem;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

a {
    color: #000;
    text-decoration: none;
}

@media only screen and (max-width: 767px) {
    .mb {
        list-style: none;
        text-align: center !important;
        float: none !important;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .ph {
        margin-bottom: 15px !important;
    }
}

.about-us-container
{
    padding:11px;
}

.WrapperTop {
    background: #1D4D4F;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #fff;
    border-radius: 10px;
}

.centerItems {
    justify-content: center;
    align-items: center;
    text-align: center;
}


.ImageGrid {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 65px;
}

/* .logo {
    width: 12.5rem;
    height: 5.5rem;
    max-width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    margin: auto;
} */