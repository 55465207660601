
    .root {
        background-color:rgba(247,250,255,255);  
        width: 100%;
        position:relative;
        margin-top:45px;
        
    }
    .headingDiv {
    width:100%;
      text-align:center;
      justify-content:center;
      align-items:center;
      background-color:rgba(247,250,255,255);
    }
   .mainHeading {
        font-size: 1.8754rem;
        /* padding: 0 5px; */
        font-weight: 600;
        color:#424242;
        /* background-color:rgba(247,250,255,255); */
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .description {
        color:#727272;
        justify-content:center;
        text-align:left;
        font-size:16px;
    }
    .terms {
        padding-top:25px;
        padding-bottom:25px;
    }
    .termsHeading {
        text-align:left;
        justify-content:left;
        align-items:left;
    }
    .termsTitle {
        font-size:1.375rem;
        font-weight:600;
        color:#424242;
        padding-bottom:15px;
        text-align:left;
        justify-content:left;
        align-items:left;
    }
  