.child-div {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  box-sizing: border-box;
}

@media (max-width: 1025px) {
  .child-div {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
    padding-bottom: 30px;
    box-sizing: border-box;
  }
}
.checkoutchild-div {
  padding-left: 140px;
  padding-right: 0px;
  padding-top: 100px;
  box-sizing: border-box;
}

@media (max-width: 911px) {
  .checkoutchild-div {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 100px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  /* .child-div {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 115px;
    
    box-sizing: border-box;
  } */
}
/* @media (max-width: 911px) {
  .child-div {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 70px;
    padding-bottom: 100px;
    box-sizing: border-box;
  }
} */
/* @media (max-width: 600px){
  .child-div {
    padding-bottom: 130px;
  }
} */
